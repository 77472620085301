import React from 'react'
import "../../styles/shipping.scss";
import {Country,State} from "country-state-city"
const Shipping = () => {
  return (
    <>
    <section className="shipping">
        <main>
            <h1>Shipping Details</h1>
            <form action="">
                <div>
                    <label>H. No.</label>
                    <input type="text" placeholder='Enter complete address..'/>
                </div>
                <div>
                    <label>
                        City
                    </label>
                    <input type="text" placeholder='Enter your city'/>
                </div>
                <div>
                    <label>Country</label>
                    <select >
                        {
                            Country && Country.getAllCountries().map(i=>(
                                <option value={i.isoCode}>{i.name}</option>
                            ))
                        }
                    </select>
                </div>
                <div>
                    <label>State</label>
                    <select >
                        {
                            State && State.getStatesOfCountry("IN").map(i=>(
                                <option value={i.isoCode}>{i.name}</option>
                            ))
                        }
                    </select>
                </div>
                <div>
                    <label>PinCode</label>
                    <input type="text" placeholder='Enter PINCODE'/>
                </div>
                <div>
                    <label>Phone Number</label>
                    <input type="number" placeholder='Enter your Phone number' />
                </div>
                <button type='submit'>
                    Confirm Order
                </button>
            </form>
        </main>
    </section>
    </>
  )
}

export default Shipping