import React from 'react'
import '../../styles/contact.scss';
import { motion } from "framer-motion";
import foodImg from "../../assets/contactfood.png";
const Contact = () => {
    return (
        <section className='contact'>
            <motion.form action=""
                initial={{
                    x: "-100vw",
                    opacity: 0,
                }}
                animate={{
                    x: 0,
                    opacity: 1,
                }}
                transition={{ delay: 0.2 }}
            >
                <h2>Contact Us</h2>
                <input type="text" placeholder='Name' />
                <input type="email" placeholder="Email" />
                <textarea cols="30" rows="10" placeholder='Message...'></textarea>
                <button type='submit'>Send</button>
            </motion.form>
            <motion.div className='formBorder' initial={{
          x: "100vw",
          opacity: 0,
        }}
        animate={{
          x: 0,
          opacity: 1,
        }}
        transition={{ delay: 0.2 }}>
                <motion.div initial={{
            y: "-100vh",
            x: "50%",
            opacity: 0,
          }}
          animate={{
            x: "50%",
            y: "-50%",
            opacity: 1,
          }}
          transition={{
            delay: 1,
          }}>
                    <img src={foodImg} alt="Namkeens in Contact Form" />
                </motion.div>
            </motion.div>
        </section>
    )
}

export default Contact