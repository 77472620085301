import React from "react";
import { Link } from "react-router-dom";
import { RiFindReplaceLine } from "react-icons/ri";
import '../../styles/about.scss';

const About = () => {
  return (
    <section className="about">
      <main>
        <h1>About Us</h1>

        <article>
          <h4>Rupa Namkeens</h4>
          <p>
            We are Rupa Namkeens wale. The place for most tasty namkeens and Indian dishes and cuisines on the
            enitre planet.
          </p>

          <p>
            Explore the various type of food and namkeens. Click below to see the
            menu
          </p>

          <Link to="/">
            <RiFindReplaceLine />
          </Link>
        </article>

        <div>
          <h2>Founder</h2>
          <article>
            <div>
              <img src={"https://yt3.googleusercontent.com/ytc/AL5GRJVVJqrQeI0bm0NL8F6BQa9_OXwbbeY_u85WGtK7=s1076-c-k-c0x00ffffff-no-rj"} alt="Founder" />
              <h3>Rupa Prasad</h3>
            </div>

            <p>
              I am Rupa Prasad, the founder of Rupa Namkeens. Affiliated to
              God's Taste...
            </p>
          </article>
        </div>
      </main>
    </section>
  );
};

export default About;