import "../../styles/orderDetails.scss";

const OrderDetails = () => {
    return (
      <section className="orderDetails">
        <main>
          <h1>Order Details</h1>
          <div>
            <h1>Shipping</h1>
            <p>
              <b>Address</b>
              {"sjda 12-32ss dsad"}
            </p>
          </div>
          <div>
            <h1>Contact</h1>
            <p>
              <b>Name</b>
              {"Preetish"}
            </p>
            <p>
              <b>Phone</b>
              {9248179487}
            </p>
          </div>
  
          <div>
            <h1>Status</h1>
            <p>
              <b>Order Status</b>
              {"Processing"}
            </p>
            <p>
              <b>Placed At</b>
              {"23-02-2002"}
            </p>
            <p>
              <b>Delivered At</b>
              {"23-02-2003"}
            </p>
          </div>
  
          <div>
            <h1>Payment</h1>
            <p>
              <b>Payment Method</b>
              {"Online"}
            </p>
            <p>
              <b>Payment Reference</b>#{"asdjfkaj"}
            </p>
            <p>
              <b>Paid At</b>
              {"23-02-2003"}
            </p>
          </div>
  
          <div>
            <h1>Amount</h1>
            <p>
              <b>Items Total</b>₹{20082}
            </p>
            <p>
              <b>Shipping Charges</b>₹{200}
            </p>
            <p>
              <b>Tax</b>₹{(20082*0.18).toFixed(2)}
            </p>
            <p>
              <b>Total Amount</b>₹{20082*1.18+ 200}
            </p>
          </div>
  
          <article>
            <h1>Ordered Items</h1>
            <div>
              <h4>Namak Paare</h4>
              <div>
                <span>{12}</span> x <span>{240}</span>
              </div>
            </div>
            <div>
              <h4>Potato Rings</h4>
              <div>
                <span>{10}</span> x <span>{200}</span>
              </div>
            </div>
            <div>
              <h4>French Fries</h4>
              <div>
                <span>{10}</span> x <span>{180}</span>
              </div>
            </div>
  
            <div>
              <h4
                style={{
                  fontWeight: 800,
                }}
              >
                Sub Total
              </h4>
              <div
                style={{
                  fontWeight: 800,
                }}
              >
                ₹{20082}
              </div>
            </div>
          </article>
        </main>
      </section>
    );
  };
  
  export default OrderDetails;