import '../../styles/profile.scss';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import { MdDashboard } from "react-icons/md";
const Profile = () => {
    const options = {
        initial: {
            y: "-100%",
            opacity: 0
        }, whileInView: {
            y: "0",
            opacity: 1
        },
        transition: {
            delay: "0.2"
        }
    };
    return (

        <>
            <section className="profile">
                <main>
                    <motion.img src="https://yt3.googleusercontent.com/ytc/AL5GRJVVJqrQeI0bm0NL8F6BQa9_OXwbbeY_u85WGtK7=s1076-c-k-c0x00ffffff-no-rj" alt="User Profile" {...options} />
                    <motion.h5 {...options}>
                        Rupa Prasad
                    </motion.h5>
                    <motion.div>
                        <Link to={"/admin/dashboard"} {...options} transition={{ delay: 0.5 }} style={{
                            borderRadius: 0,
                            backgroundColor: "rgb(40,40,40)",
                        }}><MdDashboard /> Dashboard</Link>
                    </motion.div>
                    <motion.div initial={{ x: "-100vw", opacity: 0 }} animate={{ x: "0", opacity: 1 }} transition={{ delay: 0.5 }}>
                        <Link to={"/myorders"}>Orders</Link>
                    </motion.div>
                    <motion.div initial={{ x: "-100vw", opacity: 0 }} animate={{ x: "0", opacity: 1 }} transition={{ delay: 0.3 }}>
                        <Link to={"/logout"}>Logout</Link>
                    </motion.div>
                </main>
            </section>
        </>
    )
}

export default Profile