import React from 'react'
import '../../styles/menu.scss';
import MenuCard from './MenuCard.jsx';

const Menu = () => {
  const addToCartHandler = (itemNum)=>{}
  return (
<section id="menu">
  <h1>Menu</h1>
  <div>
    <MenuCard itemNum={1} imgSrc={"https://i2.wp.com/www.bharatzkitchen.com/wp-content/uploads/2020/08/namak-Pare.jpg?fit=640%2C362&ssl=1"} price={240} title={"Namak Paare"} handler={addToCartHandler} delay="0.2"/>
    <MenuCard itemNum={2} imgSrc={"https://indiandesirasoi.in/wp-content/uploads/2022/12/Matar-ki-Kachori-Recipe-in-Hindi.jpg"} price={240} title={"Khasta Kachori"} handler={addToCartHandler} delay="0.4"/>
    <MenuCard itemNum={3} imgSrc={"https://img.buzzfeed.com/thumbnailer-prod-us-east-1/7f539fc41a5543aebfe03afed73a0b48/BFV9112_MozzarellaStickOnionRings.jpg"} price={240} title={"Potato Rings"} handler={addToCartHandler} delay="0.6"/>
    <MenuCard itemNum={4} imgSrc={"https://www.thespruceeats.com/thmb/X_JGM04VusvkuGqTVan4QmBRqjI=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/how-to-make-homemade-french-fries-2215971-hero-01-02f62a016f3e4aa4b41d0c27539885c3.jpg"} price={240} title={"French Fries"} handler={addToCartHandler} delay="0.8"/>
  </div>
</section>
    )
}

export default Menu