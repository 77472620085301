import React from 'react'
import { motion } from 'framer-motion';
import "../../styles/founder.scss";
import me from "../../assets/founder.jpg";
const Founder = () => {
  const options = {
initial:{x:"-100%",opacity:0}, whileInView:{x:0,opacity:1}
  }
  return (
<>
<section className="founder">
<motion.div {...options}>
    <img src={me} alt="Founder" width={200} height={200} />
    <h3>Rupa Prasad</h3>
    <p>Hey everyone, I am Rupa Prasad, the founder of Rupa Namkeens.
        <br />
        Our aim is to create the best namkeens in the world.
    </p>
</motion.div>
</section>
</>
    )
}

export default Founder