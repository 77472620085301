import React from 'react'
import {motion} from "framer-motion";
import '../../styles/home.scss'

import Founder from "../home/Founder.jsx";
import Menu from './Menu.jsx';
const Home = () => {
  const options ={
    initial:{
      x:"-100%",opacity:"0"
    },
    whileInView:{
      x:0,opacity:1
    }
  }
  return (
    <>
    <section className="home">
      <div>
        <motion.h1 {...options}>Rupa Namkeens</motion.h1>
        <motion.p {...options} transition={{delay:"0.2"}}>Reward yourself with tasty Namkeens</motion.p>

      </div>
      <motion.a href="#menu" initial={{y:"-100%",opacity:"0"}} whileInView={{y:"0",opacity:"1"}} transition={{delay:0.4}}>
        Explore Menu
      </motion.a>
    </section>
    <Founder />
    <Menu />
    </>
  )
}

export default Home