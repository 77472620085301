import React from 'react'
import "../../styles/footer.scss";
import { AiFillInstagram, AiFillYoutube, AiFillFacebook } from "react-icons/ai"
const Footer = () => {
    return (
        <>
            <footer>
                <div>
                    <h2>Rupa Namkeens</h2>
                    <p>We are trying to give you the best namkeens experience of your life.</p>
                    <br />
                    <em>We give attention to all the genuine feedback.</em>
                    <strong>
                        All Rights Reserved @rupanamkeens
                    </strong>
                </div>

                <aside>
                    <h4>Follow Us</h4>

                    <a href="https://youtube.com/@rupaprasad">
                        <AiFillYoutube />
                    </a>
                    <a href="https://instagram.com/rupa_namkeens">
                        <AiFillInstagram />
                    </a>
                    <a href="https://facebook.com/@rupaprasad">
                        <AiFillFacebook />
                    </a>

                </aside>
            </footer>
        </>
    )
}

export default Footer