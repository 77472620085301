import '../../styles/confirmOrder.scss';
const ConfirmOrder = () => {
    return (
        <section className="confirmOrder">
<main>
    <h1>confirm order</h1>
    <form action="">
        <div>
            <label >Cash on Delivery</label>
            <input type="radio" name="payment"/>
        </div>
        <div>
            <label>Online</label>
            <input type="radio" name="payment"  />
        </div>

        <button type="submit">Place Order</button>
    </form>
</main>
        </section>
    )
}

export default ConfirmOrder