import React from 'react'
import '../../styles/cart.scss'
import { Link } from 'react-router-dom'
const CartItem = ({ value, title, img, increment, decrement }) => {
    return (
        <div  className='cartItem'>
            <div>
                <h4>
                    {title}
                </h4>
                <img src={img} alt="Cart Item" />
            </div>
            <div>
                <button onClick={decrement}>-</button>
                <input type="number" readOnly value={value} />
                <button onClick={increment}>+</button>
            </div>
        </div>
    )
}

const Cart = () => {
    const increment =(item)=>{};
    const decrement =(item)=>{};
    return (
        <section className="cart">
            <main>
                <CartItem title={"Namak Paare"} img={"https://i2.wp.com/www.bharatzkitchen.com/wp-content/uploads/2020/08/namak-Pare.jpg?fit=640%2C362&ssl=1"} price={"240"} value={0} increment={()=>increment(1)} decrement = {()=>()=>decrement(1)} />
                <CartItem title={"Khasta Kachori"} img={"https://indiandesirasoi.in/wp-content/uploads/2022/12/Matar-ki-Kachori-Recipe-in-Hindi.jpg"} price={"140"} value={0} increment={()=>increment(2)} decrement = {()=>()=>decrement(2)} />
                <CartItem title={"Potato Rings"} img={"https://img.buzzfeed.com/thumbnailer-prod-us-east-1/7f539fc41a5543aebfe03afed73a0b48/BFV9112_MozzarellaStickOnionRings.jpg"} price={"240"} value={0} increment={()=>increment(3)} decrement = {()=>()=>decrement(3)} />
                <CartItem title={"French Fries"} img={"https://www.thespruceeats.com/thmb/X_JGM04VusvkuGqTVan4QmBRqjI=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/how-to-make-homemade-french-fries-2215971-hero-01-02f62a016f3e4aa4b41d0c27539885c3.jpg"} price={"240"} value={0} increment={()=>increment(4)} decrement = {()=>()=>decrement(4)} />


                <article>
                    <div>
                        <h4>
                            Sub Total
                        </h4>
                        <p>₹{2000}</p>
                    </div>{" "}
                    <div>
                        <h4>
                            Tax
                        </h4>
                        <p>
                        ₹{2000*0.18}
                        </p>
                    </div>{" "}
                    <div>
                        <h4>
                            Shipping Charges
                        </h4>
                        <p>
                        ₹{200}
                        </p>
                    </div>{" "}
                    <div>
                        <h4>Total</h4>
                        <p>₹{2000*1.18 + 200}</p>
                    </div>

                    <Link to="/shipping">Checkout</Link>
                </article>
            </main>
        </section>
    )
}

export default Cart